@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    h1{
        @apply font-bold
    }

    h2{
        @apply font-semibold
    }
}

@layer components{
    .label-tab {
        @apply font-medium text-gray-500
    }

    .label-tab-active {
        @apply font-[700] text-primary
    }

    .screen-with-nav {
        @apply w-full h-full min-h-screen bg-bg 
    }

    .shadow-nav {
        @apply shadow-[0px_-1px_16px_-10px_rgba(0,0,0,0.5)]
    }

    .header {
        @apply w-full 2xs:h-[100px] h-[80px] justify-center items-center flex top-0 max-w-[600px]
    }
}

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
}

/* * {
    overscroll-behavior-y: contain;
} */

@keyframes Rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }